import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import siteBannerService from '../../../data/services/siteBanner.service';
import { localStorageGet, localStorageSet } from '../../../hooks/useLocalStorageSync';

export const localStorageBannerKey = 'seen_banners';

/**
 * This hook is used to fetch the site banners from the API and filter out the ones that don't need to be
 * shown.
 */
const useSiteBanner = () => {
    const [seenBanners, setSeenBanners] = useState<string[]>(localStorageGet(localStorageBannerKey) || []);

    // Gets the banners from the API.
    const { data: axiosResponse } = useQuery('siteBanner', siteBannerService.getSiteBanner);

    // Mark a banner as seen and stores it in local storage.
    const markBannerAsSeen = (bannerId: string | number) => {
        const currentSeenBanners = localStorageGet(localStorageBannerKey) || [];
        currentSeenBanners.push(bannerId.toString());

        localStorageSet(localStorageBannerKey, currentSeenBanners);
        setSeenBanners(currentSeenBanners);
    };

    // Filters the API banners that need to be shown.
    const unSeenBanners = useMemo(() => {
        const banners = axiosResponse?.data?.data || [];

        return banners.filter(({ uuid }) => {
            // If the banner has already been seen, don't show it.
            if (seenBanners.includes(uuid.toString())) {
                return false;
            }

            // Filters if the front end needs them later on as the API currently does it.
            // If the banner is disabled, don't show it.
            // if (status === 'DISABLED') {
            //     return false;
            // }

            // If the current date is past the end date for the banner.
            // const endDate = end ? dayjs(end, 'YYYY-MM-DD HH:mm:ss') : null;
            // if (endDate && dayjs().isAfter(endDate)) {
            //     return false;
            // }

            // If the current date is before the start date for the banner.
            // const startDate = start ? dayjs(start, 'YYYY-MM-DD HH:mm:ss') : null;
            // if (startDate && dayjs().isBefore(startDate)) {
            //     return false;
            // }

            return true;
        });
    }, [axiosResponse, seenBanners]);

    return {
        siteBanners: unSeenBanners,
        markBannerAsSeen,
    };
};

export default useSiteBanner;
