import React from 'react';
import { Link } from 'gatsby';
import { genericHashLink } from 'react-router-hash-link';

import { useAuthContext } from '../../../data/context/authContext';

import IconProfileLight from '../../_ui/_blocks/Icons/IconProfileLight';
import Typography from '../../_new_components/common/Typography/Typography';
import BaseIcon from '../../_new_components/common/Icons/BaseIcon/BaseIcon';
import Button from '../../_new_components/common/Buttons/Button/Button';

import * as styles from './AccountDropdown.module.css';
import * as dropdownStyles from '../CraftMenuTypes/MainNavigation/MainNavigationDropdown/MainNavigationDropdown.module.css';

interface Props {
    disableAccountDropdownOnMobile?: boolean;
}

const AccountDropdown = ({ disableAccountDropdownOnMobile = false }: Props) => {
    const { isLoggedIn, logout, patientName } = useAuthContext();

    const signInText = isLoggedIn ? `Hello ${patientName || 'user'}` : 'Sign in';
    const signInLink = isLoggedIn ? '/account' : '/login';

    const ScrollLink = genericHashLink(Link);

    // Can't pass logout directly to the button as the redirect param will be passed as an event object.
    const handleLogout = () => logout();

    const hideOnMobileClassName = disableAccountDropdownOnMobile ? styles.listItemMobileHidden : undefined;

    return (
        <div className={`${styles.listItem} ${hideOnMobileClassName}`}>
            <Link
                to={signInLink}
                aria-label="Your Independent Pharmacy account"
                title="Your Independent Pharmacy account"
                className={styles.link}
            >
                <Typography typeset="heading" size="100" color="white">
                    {signInText}
                </Typography>
                <BaseIcon>
                    <IconProfileLight />
                </BaseIcon>
            </Link>
            {isLoggedIn ? (
                <ul className={dropdownStyles.dropdown}>
                    <li>
                        <Link to="/account" className={dropdownStyles.dropdownItem}>
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <ScrollLink to="/account/orders#order-history" className={dropdownStyles.dropdownItem}>
                            Your orders
                        </ScrollLink>
                    </li>
                    <li>
                        <Link to="/account/orders/treatment-reorders" className={dropdownStyles.dropdownItem}>
                            Treatment reorders
                        </Link>
                    </li>
                    <li className={styles.border} />
                    <li>
                        <Button
                            variant="none"
                            size="none"
                            title="Sign out of your account"
                            onClick={handleLogout}
                            className={`!w-full ${dropdownStyles.dropdownItem}`}
                        >
                            Sign out
                        </Button>
                    </li>
                </ul>
            ) : null}
        </div>
    );
};

export default AccountDropdown;
