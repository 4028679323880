import React from 'react';

import {
    useSelectedVariantContext,
    useSelectedQuantityContext,
    useBasketBlockerContext,
} from '../../TreatmentSelectorContext/TreatmentSelectorContext';
import { useAuthContext } from '../../../../../data/context/authContext';
import Patient from '../../../../../types/api/patient/Patient';

import ButtonBusy from '../../../common/Buttons/ButtonBusy/ButtonBusy';
import AddToBasketButton from '../Buttons/AddToBasketButton/AddToBasketButton';
import FindAlternativesButton from '../Buttons/FindAlternativesButton/FindAlternativesButton';
import OpenBasketButton from '../Buttons/OpenBasketButton/OpenBasketButton';
import ContinueToAssessmentButton from '../Buttons/ContinueToAssessmentButton/ContinueToAssessmentButton';
import ReorderTreatmentButton from '../Buttons/ReorderTreatmentButton/ReorderTreatmentButton';

type ButtonBusyProps = React.ComponentProps<typeof ButtonBusy>;

interface Props extends Omit<ButtonBusyProps, 'children' | 'variant' | 'title'> {
    conditionSlug: string;
}

/**
 * Decides what submission button to show on the treatment page for the selector.
 */
const TreatmentPageButton = ({ className, conditionSlug, loading, onClick, type = 'submit', size = 'large' }: Props) => {
    const selectedVariant = useSelectedVariantContext();
    const selectedQuantity = useSelectedQuantityContext();
    const blockers = useBasketBlockerContext();

    const { patient } = useAuthContext();

    const refills = (patient as Patient | undefined)?.refills || [];
    const isRefill = refills.find(({ reference }) => reference === selectedVariant.id);

    const blockerTypes = blockers.map(({ type }) => type);
    const quantity = selectedQuantity?.quantity || 0;

    if (blockerTypes.includes('ALREADY_IN_BASKET') || blockerTypes.includes('BLOCKED_BY_BASKET')) {
        return <OpenBasketButton className={className} size={size} />;
    }

    if (blockerTypes.includes('OUT_OF_STOCK') || blockerTypes.includes('GENDER_RESTRICTED')) {
        return <FindAlternativesButton className={className} size={size} conditionSlug={conditionSlug} />;
    }

    if (['PRODUCT', 'PMED'].includes(selectedVariant.type)) {
        return <AddToBasketButton className={className} size={size} loading={loading} type={type} onClick={onClick} disabled={!quantity} />;
    }

    if (isRefill) {
        return (
            <ReorderTreatmentButton
                className={className}
                size={size}
                loading={loading}
                type={type}
                onClick={onClick}
                disabled={!quantity}
            />
        );
    }

    return <ContinueToAssessmentButton className={className} size={size} disabled={!quantity} conditionSlug={conditionSlug} />;
};

export default TreatmentPageButton;
