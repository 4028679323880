import React, { Fragment } from 'react';

import { useBasketContext } from '@data/context/basketContext/basketContext';

import Loading from '../_ui/_blocks/Loading/Loading';
import Flyout from '../_ui/Flyout/Flyout';
import BasketSummary from './BasketPreview/BasketSummary/BasketSummary';
import { BasketItem as BasketItemType } from '../../types/api/basket/BasketItem';

import BasketEmpty from './BasketEmpty/BasketEmpty';
import BasketItem from './BasketList/BasketItem/BasketItem';

const Basket = () => {
    // @ts-expect-error
    const { basket, basketIsOpen, setBasketIsOpen, initialLoadComplete } = useBasketContext();

    const items: BasketItemType[] = basket && basket.items ? basket.items : [];

    const basketSubtotal = basket?.total || 0;

    const handleBasketClose = () => setBasketIsOpen(false);

    return (
        <Flyout isOpen={basketIsOpen} onClose={handleBasketClose} title="Your basket">
            <div data-testid="saved-basket-preview" className="flex flex-col h-full border-t border-solid border-decorators-subtle">
                <Loading isLoading={!initialLoadComplete} iconClassName="flex-grow">
                    {items.length ? (
                        <Fragment>
                            <ul className="p-100 space-y-100 flex-grow overflow-auto">
                                {items.map((item) => (
                                    <BasketItem key={item.id} item={item} />
                                ))}
                            </ul>
                            <BasketSummary basketSubtotal={basketSubtotal} />
                        </Fragment>
                    ) : (
                        <BasketEmpty closeBasket={handleBasketClose} />
                    )}
                </Loading>
            </div>
        </Flyout>
    );
};

export default Basket;
