import React from 'react';
import clx from 'classnames';

import * as styles from './BaseIcon.module.css';

enum Colors {
    inherit = 'fill-inherit',
    'tertiary-300' = 'fill-tertiary-300',
    'tertiary-400' = 'fill-tertiary-400',
    'tertiary-500' = 'fill-tertiary-500',
    'secondary-100' = 'fill-secondary-100',
    'secondary-500' = 'fill-secondary-500',
    'secondary-600' = 'fill-secondary-600',
    'white' = 'fill-neutrals-white',
    'black' = 'fill-neutrals-black',
    'highlight' = 'fill-content-highlight',
    'error-500' = 'fill-status-error-500',
    'success-500' = 'fill-status-success-500',
    'info-500' = 'fill-status-info-500',
    'warning-500' = 'fill-status-warning-500',
    'accent' = 'fill-content-accent',
    'quiet' = 'fill-content-quiet',
    'info' = 'fill-content-info',
}

interface Props {
    children: React.ReactNode;
    size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
    width?: number;
    height?: number;
    viewBox?: string;
    color?: keyof typeof Colors;
    className?: string;
    title?: string;
}

const BaseIcon = ({ children, size = 'medium', width, height, viewBox = '0 0 48 48', color = 'inherit', className, title }: Props) => {
    const classNames = clx('pointer-events-none', className, styles[size], Colors[color]);
    return (
        <svg viewBox={viewBox} className={classNames} width={width} height={height} title={title}>
            {children}
        </svg>
    );
};

export default BaseIcon;
