import React from 'react';

import getTotalTreatmentAndConditionCounts from '../../../data/staticQueryHooks/useTotalTreatmentAndConditionCount';

import BaseIcon from '../../_new_components/common/Icons/BaseIcon/BaseIcon';
import IconArrowRight from '../../_ui/_blocks/Icons/Navigation/IconArrowRight';
import Typography from '../../_new_components/common/Typography/Typography';
import ButtonLink from '../../_new_components/common/Buttons/ButtonLink/ButtonLink';

import * as styles from './BasketEmpty.module.css';

interface Props {
    closeBasket: () => void;
}

const BasketEmpty = ({ closeBasket }: Props) => {
    const { treatmentCount, conditionCount } = getTotalTreatmentAndConditionCounts();

    return (
        <div className="text-center p-100">
            <img
                className={styles.image}
                src="/images/illustrations/empty-basket.svg"
                alt="An empty shopping cart"
                width={212}
                height={202}
            />
            <Typography typeset="subheading" size="090" className="block mt-200">
                You don't have anything in your basket yet
            </Typography>
            <Typography as="p" typeset="note" color="quiet" className="mt-050">
                Find the right treatment for you; We stock {treatmentCount} medicines for {conditionCount} conditions.
            </Typography>
            <ButtonLink
                to="/treatments"
                title="View all treatments"
                className="flex items-center justify-center mt-200"
                onClick={closeBasket}
            >
                View all treatments
                <BaseIcon size="xsmall">
                    <IconArrowRight />
                </BaseIcon>
            </ButtonLink>
            <ButtonLink
                variant="secondary"
                to="/conditions"
                title="View all conditions"
                className="flex items-center justify-center mt-100"
                onClick={closeBasket}
            >
                View all conditions
                <BaseIcon size="xsmall">
                    <IconArrowRight />
                </BaseIcon>
            </ButtonLink>
        </div>
    );
};

export default BasketEmpty;
