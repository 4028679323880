import React from 'react';
import clx from 'classnames';

import Label from '../Label/Label';

import { BorderRadiusMap } from '../styles/types';
import * as styles from '../styles/Inputs.module.css';

type LabelProps = React.ComponentProps<typeof Label>;

interface Props extends React.AllHTMLAttributes<HTMLInputElement>, LabelProps {
    id: string;
    label?: string;
    className?: string;
    wrapperTag?: 'div';
    isValid?: boolean;
    isDirty?: boolean;
    type?: 'number' | 'tel';
    borderRadius?: keyof typeof BorderRadiusMap;
    dataLpignore?: boolean;
}

const NumberInput = ({
    id,
    label,
    description,
    className,
    wrapperTag,
    isDirty,
    isValid,
    borderRadius = 'default',
    type = 'number',
    optional,
    ...rest
}: Props) => {
    let inputOnlyClassName = clx(styles.field, BorderRadiusMap[borderRadius], {
        [styles.fieldValid]: isDirty && isValid,
        [styles.fieldInvalid]: isDirty && !isValid,
    });

    if (!label) {
        inputOnlyClassName = clx(inputOnlyClassName, className);
        return <input {...rest} id={id} className={inputOnlyClassName} type={type} inputMode="numeric" pattern="[0-9]*" />;
    }

    const Wrapper = wrapperTag || 'div';
    const wrapperClassNames = clx(className, styles.labelWrap);

    return (
        <Wrapper className={wrapperClassNames}>
            <Label id={id} label={label} description={description} optional={optional} />
            <input {...rest} id={id} className={inputOnlyClassName} type={type} inputMode="numeric" pattern="[0-9]*" />
        </Wrapper>
    );
};

export default NumberInput;
