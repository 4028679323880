import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface Props {
    children: React.ReactNode;
    show?: boolean;
    onClose: () => void;
}

const Modal = ({ children, show = false, onClose }: Props) => (
    <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="z-800 h-full fixed inset-0" onClose={onClose}>
            <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-easeOut duration-fast"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-all ease-easeIn duration-fast"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="overlay absolute inset-0 opacity-100 z-0 pointer-events-none bg-layers-soft bg-opacity-90" />
            </Transition.Child>

            <Transition.Child
                as={Fragment}
                enter="transition-all ease-easeOut duration-fast"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition-all ease-easeIn duration-fast"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Dialog.Panel className="absolute inset-0 grid w-full h-full p-100 overflow-y-auto place-items-center">
                    {children}
                </Dialog.Panel>
            </Transition.Child>
        </Dialog>
    </Transition>
);

export default Modal;
