import React, { Fragment } from 'react';

import CraftMenuLink from '../../../CraftMenuLink/CraftMenuLink';
import withLocation from '../../../../../hoc/withLocation';

import IconChevronDown from '../../../../_ui/_blocks/Icons/Navigation/IconChevronDown';
import MegamenuTreatments from './MegamenuTreatments';
import MegamenuConditions from './MegamenuConditions';
import BaseIcon from '../../../../_new_components/common/Icons/BaseIcon/BaseIcon';

type ComponentList = {
    [key: string]: React.ComponentType<any> | null;
};

const componentList: ComponentList = {
    treatments: MegamenuTreatments,
    conditions: MegamenuConditions,
};

interface Location {
    hash: string;
    host: string;
    hostname: string;
    href: string;
    origin: string;
    pathname: string;
    port: string;
    protocol: string;
    search: string;
}

interface Props {
    node: {
        title: string;
        classes: string;
        children: {
            id: string;
            title: string;
            level: string;
            url: string;
            parent: {
                id: string;
            };
        }[];
        type: string;
        url: string;
    };
    /** The navigation tree to be rendered */

    conditions: {
        id: string;
    }[];
    /**
     * HOC - pulled from reach router.
     */
    location: Location;
    className?: string;
    activeClassName?: string;
}

const MegaMenu = ({ node, location, conditions = [], className, activeClassName }: Props) => {
    const { classes, children, title } = node;

    const type = classes.replace('_has-megamenu--', '');

    const ComponentToRender: React.ComponentType<any> | null = componentList[type]! || null;

    const hideMegaMenu = location.pathname === '/' && type === 'treatments'; // If the route is the homepage and the menu item is treatments, hide the megamenu.
    const disableMegaMenu = type === 'treatments' && location.pathname.includes('/gppc'); // If the menu item is the treatments item and contains "/gppc".

    return (
        <Fragment>
            <CraftMenuLink menuNode={node} className={className} activeClassName={activeClassName}>
                {title}
                {disableMegaMenu === false && hideMegaMenu === false ? (
                    <BaseIcon size="xsmall">
                        <IconChevronDown />
                    </BaseIcon>
                ) : null}
            </CraftMenuLink>
            {disableMegaMenu === false ? (
                <ComponentToRender
                    nodes={children}
                    conditions={conditions}
                    className={hideMegaMenu ? 'pointer-events-none invisible' : ''}
                />
            ) : null}
        </Fragment>
    );
};

export default withLocation(MegaMenu);
