import React from 'react';

import Attention from '../../../common/Attention/Attention';

const QuantityNotAvailableNotification = () => (
    <Attention heading="Quantity not available">
        The quantity of this treatment could not be found. Please select a different quantity.
    </Attention>
);

export default QuantityNotAvailableNotification;
