import { BasketItem } from '@root/types/api/basket/BasketItem';
import { useBasketContext, formatBasketConsultationIds } from '../basketContext';
import useDataApi from '../../../../hooks/useDataApi';
import basketService from '../../../services/basket.service';
import { removeItemFromArray } from '../../../../helpers/manipulateArray';
import dispatchGoogleAnalyticsEvent, { gaEvents, gaFormatters } from '../../../../modules/analytics/google/dispatchGoogleAnalyticsEvent';
import useTreatments from '../../../staticQueryHooks/useTreatment';
import useTreatmentImage from '../../../staticQueryHooks/useTreatmentImage';

const useManageBasketItems = () => {
    // @ts-expect-error
    const { basket, setBasket, basketUuid } = useBasketContext();

    const { getTreatmentDescription } = useTreatments();
    const { getTreatmentImage } = useTreatmentImage();

    /**
     * Provides polling function that is added to the usePollingHook further down. Also provides
     * error, loading and response states.
     */
    const [{ isLoading: isUpdating, error: updateBasketError }, updateBasket] = useDataApi(basketService.updateBasket);

    /**
     * Calls the createBasket API with response data.
     */
    const [{ isLoading: isCreating, isError: createBasketError }, createBasket] = useDataApi(basketService.createBasket);

    /**
     * Removes an item from the basket by item ID.
     * @param {number} id - ID of the basket item to remove.
     */
    const updateItems = async (items: number) => {
        const postData = { action: 'UPDATE_ITEMS', items };
        const response = await updateBasket(basketUuid, postData);

        if (!response) return null;

        /**
         * Because treatment was changed to treatmentId, we need to loop through a basket and treatmentId
         * basket.item.consultation.treatmentId.
         */
        let newBasket = response.data && response.data.data ? response.data.data : { items: [] };
        newBasket = formatBasketConsultationIds(newBasket);

        setBasket(newBasket);
        return response;
    };

    /**
     * Removes an item from the current basket state before waiting for response.
     * @param {array} ids - array of basket item IDs to remove.
     */
    const removeBasketItemsFromState = (ids: { id: number }[]) => {
        const basketClone = { ...basket };

        ids.forEach(({ id }) => {
            const basketItem = basketClone.items.find((item: { id: number }) => item.id === id);

            if (!basketItem) return;

            const itemPrice = basketItem.total;

            basketClone.items = removeItemFromArray(basketClone.items, id, 'id');
            basketClone.total = basket.total - itemPrice;
        });

        setBasket(basketClone);
    };

    /**
     * Removes items from the basket by item ID.
     * @param {array} id - Array of IDs to remove from the basket [{ id: id }].
     */
    const deleteItems = async (ids: { id: number }[]) => {
        const postData = { action: 'DELETE_ITEMS', items: ids };
        removeBasketItemsFromState(ids);

        const response = await updateBasket(basketUuid, postData);
        if (!response) return null;

        setBasket(response.data && response.data.data ? response.data.data : { items: [] });
        return response;
    };

    /**
     * Adds an item to the current active basket.
     * @param {object} item - Item to be added.
     */
    const addItem = async (item: BasketItem) => {
        let postData: { items: (typeof item)[]; action: string; total: number } = { items: [item], action: '', total: 0 };
        let response;

        if (basketUuid) {
            postData = { ...postData, action: 'ADD_ITEMS' };
            response = await updateBasket(basketUuid, postData);
        } else {
            postData = { ...postData, total: postData.items[0].total };
            response = await createBasket(postData);
        }

        if (!response) {
            return null;
        }

        /**
         * Because treatment was changed to treatmentId, we need to loop through a basket and treatmentId
         * basket.item.consultation.treatmentId.
         */
        let newBasket = response.data.data;
        newBasket = formatBasketConsultationIds(newBasket);

        setBasket(newBasket);
        dispatchGoogleAnalyticsEvent(gaEvents.AddToBasket, gaFormatters.addToBasket(item, getTreatmentImage, getTreatmentDescription));
        return response;
    };

    return {
        updateItems,
        deleteItems,
        addItem,

        error: updateBasketError || createBasketError,
        isLoading: isUpdating || isCreating,
    };
};

export default useManageBasketItems;
