import sendSentryMessage from '../../../../../../../../helpers/sendSentryMessage';
import { ProductVariant } from '../../../../../../../../types/api/products/Product';
import { ProductsQuantity } from '../../../../../../../../types/api/products/ProductsQuantity';
import QuantityTypes from '../../../../../../../../types/enums/QuantityTypes';
import modifyTimedQuantityWithNewQuantity from '../modifyTimedQuantityWithNewQuantity/modifyTimedQuantityWithNewQuantity';

type InitialQuantity = ProductsQuantity['quantity'];

/**
 * Checks if there's a quantity in the URL. If there is, check it's a number and return it. Otherwise return
 * null.
 */
export const getUrlQuantity = () => {
    const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');
    const urlQuantity = parseInt(urlParams.get('quantity') || '');
    return !Number.isNaN(urlQuantity) ? urlQuantity : null;
};

/**
 * Returns a quantity or undefined for a TIMED quantity type.
 */
export const getTimedQuantity = (quantities: ProductsQuantity[], initialQuantity?: InitialQuantity) => {
    const urlQuantity = getUrlQuantity();
    return modifyTimedQuantityWithNewQuantity(quantities[0], initialQuantity || urlQuantity || 0);
};

/**
 * Returns a quantity for a REGULAR quantity type.
 */
export const getRegularQuantity = (quantities: ProductsQuantity[], initialQuantity?: InitialQuantity) => {
    if (initialQuantity) {
        const foundInitialQuantity = quantities.find(({ quantity }) => quantity === initialQuantity);
        if (foundInitialQuantity) {
            return foundInitialQuantity;
        }
    }

    const urlQuantity = getUrlQuantity();
    if (urlQuantity) {
        const foundUrlQuantity = quantities.find(({ quantity }) => quantity === urlQuantity);
        if (foundUrlQuantity) {
            return foundUrlQuantity;
        }
    }

    return quantities.find((quantity) => quantity.default) || quantities[0];
};

/**
 * Gets the default quantity for a TIMED or REGULAR quantity type.
 */
const getDefaultQuantity = (variant: ProductVariant, initialQuantity?: InitialQuantity) => {
    const { quantities, quantity_type: quantityType, id } = variant;

    if (quantities.length === 0) {
        return null;
    }

    if (quantityType === QuantityTypes.timed) {
        return getTimedQuantity(quantities, initialQuantity);
    }

    if (quantityType === QuantityTypes.regular) {
        return getRegularQuantity(quantities, initialQuantity);
    }

    sendSentryMessage('Unknown quantity type given for treatment selector.', (scope) => {
        scope.setExtra('Quantity type', quantityType);
        scope.setExtra('Variant ID', id);
    });

    return null;
};

export default getDefaultQuantity;
