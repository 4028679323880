import React from 'react';

import formatQuantityLabel from '../../../../../Treatment/helpers/formatQuantityLabel';
import { BasketItem } from '../../../../../../types/api/basket/BasketItem';

import IconChevronDown from '../../../../../_ui/_blocks/Icons/Navigation/IconChevronDown';
import BaseIcon from '../../../../../_new_components/common/Icons/BaseIcon/BaseIcon';
import Button from '../../../../../_new_components/common/Buttons/Button/Button';
import Typography from '../../../../../_new_components/common/Typography/Typography';

import * as styles from './QuantityButton.module.css';

interface Props {
    onClick: (args: any) => void;
    item: BasketItem;
}

const QuantityButton = ({ onClick, item }: Props) => {
    if (item.consultation?.type === 'REFILL') {
        return (
            <Typography typeset="note" size="080" color="quiet">
                {`${formatQuantityLabel(item)} (Refill)`}
            </Typography>
        );
    }

    return (
        <Button
            variant="none"
            size="none"
            type="button"
            className={styles.button}
            onClick={onClick}
            title={`Change quantity of ${item.treatment}`}
        >
            <span className="flex-grow ellipsis">{formatQuantityLabel(item)}</span>
            <BaseIcon size="xxsmall">
                <IconChevronDown />
            </BaseIcon>
        </Button>
    );
};

export default QuantityButton;
