import { isBloomreachEnabled } from '../../../config/config';
import Analytics from '../Analytics';

export interface Exponea {
    start: () => void;
}

/**
 * Class to manage bloomreach analytics actions.
 * Main script is loaded in via gatsby-ssr config file.
 * @see https://documentation.bloomreach.com/engagement/docs/js-sdk
 */
class BloomreachAnalytics extends Analytics {
    private hasExponeaBeenStarted = false;
    readonly isEnabled = () => isBloomreachEnabled() && this.isAnalyticsEnabled();

    constructor() {
        super('bloomreach');
    }

    readonly startExponea = () => {
        const startExponea = typeof window !== 'undefined' ? window?.exponea?.start : null;

        if (!startExponea) {
            this.logEvent('exponea-not-available');
            return;
        }

        if (this.hasExponeaBeenStarted) {
            this.logEvent('exponea-already-started');
            return;
        }

        if (!this.isEnabled()) {
            this.logEvent('exponea-disabled');
            return;
        }

        startExponea();
        this.hasExponeaBeenStarted = true;
        this.logEvent('exponea-start');
    };
}

export default new BloomreachAnalytics();
