import React from 'react';

import Button from '../../../_new_components/common/Buttons/Button/Button';
import BaseIcon from '../../../_new_components/common/Icons/BaseIcon/BaseIcon';
import IconBin from '../../_blocks/Icons/IconBin';

import * as styles from './BinButton.module.css';

export interface Props {
    title: string;
    showButton?: boolean;
    onClick: ((e: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
    className?: string;
}

const BinButton = ({ onClick = undefined, title = 'item', showButton = true, className = '' }: Props) => (
    <Button
        variant="none"
        size="none"
        data-testid="delete-button"
        aria-label={title}
        title={title}
        className={`${styles.button} ${showButton ? '!opacity-0' : ''} ${className}`}
        onClick={onClick}
    >
        <BaseIcon size="small">
            <IconBin />
        </BaseIcon>
    </Button>
);

export default BinButton;
