// extracted by mini-css-extract-plugin
export var animateInHorizontally = "nPl12KiplVVGTv9VyijjRA==";
export var animateInVertically = "AG5vNuHyP2m1qOfXfGmxTQ==";
export var backButton = "N+GZvFoseM4DVmGHdMmkbQ==";
export var close = "_2NZXI+0k9NfTw1RS4Equ0g==";
export var directionBottom = "NcWi-AUrpMJzeTXevMxaLw==";
export var directionLeft = "XIjzPmKX3-D4gC-KB0c2Jg==";
export var directionRight = "T9UgZaz4hdImm2NbQz9puQ==";
export var directionTop = "_0GQfiuAnfOCos5bk2LRsRA==";
export var exitTransition = "FbzXozG4ERbsW2tlWdIMeA==";
export var flyout = "HU9GPxd11dnnR4eyuWDoaA==";
export var header = "_2Em81XcH4q0yCsHA-PCrUg==";
export var inner = "NbV7lLZoVAH+HU-RTVe9Tw==";
export var overlay = "_8lTfxYFJuCiKpHaxGCpyJg==";