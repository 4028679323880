import React, { useMemo } from 'react';

import { selectorScrollTo } from '../../../../helpers/scrollTo';
import TreatmentCache from '../../../../types/api/products/TreatmentCache';
import formatPrice from '../../../../helpers/formatPrice';
import { useSelectedVariantContext } from '../TreatmentSelectorContext/TreatmentSelectorContext';
import getPercentageSaving from '../hooks/useFormatQuantitiesForDisplay/helpers/getPercentageSaving';

import Typography from '../../common/Typography/Typography';
import StarRating from '../../common/StarRating/StarRating';
import Tag from '../../common/Tag/Tag';

interface Props {
    title: string;
    review: TreatmentCache['selectedReview'];
    fromPrice: number;
    scrollToSelector?: string; // The selector the reviews click will scroll to.
    headingTag?: 'h3' | 'h1';
}

interface ReviewNoLinkProps {
    children: React.ReactNode;
}

interface ReviewWithLinkProps extends ReviewNoLinkProps {
    scrollToSelector: string;
}

/**
 * Reviews component that is not wrapped in a scroll link.
 */
const ReviewNoLink = ({ children }: ReviewNoLinkProps) => <div className="flex items-center gap-050">{children}</div>;

/**
 * Reviews component that is wrapped in a scroll link.
 */
const ReviewWithLink = ({ scrollToSelector, children }: ReviewWithLinkProps) => {
    const onReviewsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        selectorScrollTo(scrollToSelector);
    };

    return (
        <a onClick={onReviewsClick} href="#treatment-reviews" className="flex items-center gap-050" title="View reviews">
            {children}
        </a>
    );
};

/**
 * Header component for the treatment selector.
 */
const Header = ({ title, review, fromPrice, scrollToSelector, headingTag = 'h3' }: Props) => {
    const selectedVariant = useSelectedVariantContext();
    const { numRatings, averageRating } = review;

    // Calculates the percentage saving between the selected variant quantities.
    const percentageSaving = useMemo(
        () => getPercentageSaving(selectedVariant.quantities.sort((a, b) => a.quantity - b.quantity)),
        [selectedVariant]
    );

    // If there is a scrollToSelector, wrap the reviews in a scroll link.
    const ReviewsWrapper = scrollToSelector ? ReviewWithLink : ReviewNoLink;

    return (
        <div>
            <div className="flex gap-100 justify-between items-center">
                <Typography as={headingTag} typeset="title" lineHeight="400">
                    {title}
                </Typography>
                {fromPrice ? (
                    <Typography as="div" typeset="heading" lineHeight="none" className="whitespace-nowrap">
                        <Typography size="080">from</Typography> {formatPrice(fromPrice)}
                    </Typography>
                ) : null}
            </div>
            {review.numRatings || percentageSaving ? (
                <div className="flex gap-100 justify-between items-center">
                    {review.numRatings ? (
                        <ReviewsWrapper scrollToSelector={scrollToSelector || ''}>
                            <StarRating rating={averageRating} />
                            <Typography size="080" lineHeight="none">
                                {numRatings} reviews
                            </Typography>
                        </ReviewsWrapper>
                    ) : null}
                    {percentageSaving ? (
                        <Tag size="large" color="error" className="flex-shrink-0">
                            Save up to {percentageSaving}%
                        </Tag>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default Header;
