import React, { useCallback } from 'react';
import { navigate } from 'gatsby';

import { useSearchContext } from '../../../data/context/searchContext';

import IconSearch from '../../_ui/_blocks/Icons/Navigation/IconSearch';
import IconCross from '../../_ui/_blocks/Icons/Navigation/IconCross';

import * as styles from './SearchInput.module.css';
import BaseIcon from '../../_new_components/common/Icons/BaseIcon/BaseIcon';

interface SearchInputProps {
    variant?: 'callout' | 'searchOverlay' | 'searchPage' | 'siteHeader';

    inputRef?: React.RefObject<HTMLInputElement> | undefined;
    /** the name of the search component */
    name?: string;
    /** The label of the search component */
    label?: string;
    /** Place holder displayed in the search component */
    placeholder?: string;
    /** Class name on the search component */
    className?: string;
    /** Class name displayed on the wrapping div */
    wrapperClassName?: string;
    /** Whether this input should trigger the SearchOverlay to open. */
    invokesOverlay?: boolean;
    /** Whether a search icon should be displayed or not */
    /** Whether or not to display a button that clears the input field. */
    clear?: boolean;
}

/**
 * Search input
 * This search input depends on the search ontext and directly interacts with the algolia api via the searchTerm being set.
 * This also allows us to set the overlay to open depending on props being passed in.
 */
const SearchInput = ({
    inputRef = undefined,
    name = 'search',
    label = 'Search for a condition or treatment',
    placeholder = 'Search for a condition or treatment...',
    className = '',
    wrapperClassName = '',
    invokesOverlay = false,
    clear = false,
    variant = 'callout',
}: SearchInputProps) => {
    const { searchTerm, setSearchTerm, setShowOverlay } = useSearchContext();

    const clearSearch = useCallback(() => {
        setSearchTerm('');
        setShowOverlay(false);
    }, [setSearchTerm]);

    const isSearchPage = typeof window !== 'undefined' ? window.location.href.indexOf('/search-results') > -1 : false;

    const onChange = useCallback(
        (e: { currentTarget: { value: string } }) => {
            const val = e.currentTarget.value;

            setSearchTerm(val);

            if (invokesOverlay) {
                if (!isSearchPage && val.length > 2) setShowOverlay(true);
            }
        },
        [setSearchTerm, setShowOverlay, invokesOverlay, isSearchPage]
    );

    const goToSearchPage = (e: { key: string }) => {
        if (e.key === 'Enter' && !isSearchPage) navigate(`/search-results?searchTerm=${searchTerm}`);
    };

    return (
        <div className={`${styles.search} ${styles[variant]} ${wrapperClassName}`}>
            <label htmlFor={name} className="hidden">
                {label}
            </label>

            {['searchOverlay', 'siteHeader'].includes(variant) && (
                <BaseIcon className="flex-shrink-0" size="small">
                    <IconSearch />
                </BaseIcon>
            )}

            <input
                ref={inputRef}
                data-testid="search-input"
                name={name}
                id={name}
                type="search"
                className={`flex-grow ${className}`}
                aria-label={label}
                placeholder={placeholder}
                value={searchTerm}
                onChange={onChange}
                onKeyDown={goToSearchPage}
            />

            {clear && (
                <button type="button" className="appearance-none flex-shrink-0 p-050" onClick={clearSearch} title="Click to close search">
                    <BaseIcon size="xsmall">
                        <IconCross />
                    </BaseIcon>
                </button>
            )}
            {['callout', 'searchPage'].includes(variant) && (
                <button
                    type="button"
                    className="appearance-none flex-shrink-0 p-050"
                    onClick={() => navigate(`/search-results?searchTerm=${searchTerm}`)}
                    aria-label="Search"
                >
                    <BaseIcon size="small">
                        <IconSearch />
                    </BaseIcon>
                </button>
            )}
        </div>
    );
};

export default SearchInput;
