import React from 'react';

import { useSelectedQuantityContext } from '../TreatmentSelectorContext/TreatmentSelectorContext';
import formatPrice from '../../../../helpers/formatPrice';

import Typography from '../../common/Typography/Typography';

interface Props {
    price?: number;
}

const SelectorPrice = ({ price = 0 }: Props) => {
    const selectedQuantity = useSelectedQuantityContext();

    return (
        <div className="flex justify-between items-end gap-100">
            <Typography typeset="heading" size="200">
                Total
            </Typography>
            <Typography typeset="heading" color="highlight" size="200">
                {formatPrice(price || selectedQuantity?.price || 0)}
            </Typography>
        </div>
    );
};

export default SelectorPrice;
