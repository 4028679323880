import React, { useEffect, useRef } from 'react';

import BaseIcon from '../../../../_new_components/common/Icons/BaseIcon/BaseIcon';
import IconChevronRight from '../../../../_ui/_blocks/Icons/Navigation/IconChevronRight';
import CraftMenuLink from '../../../CraftMenuLink/CraftMenuLink';
import Typography from '../../../../_new_components/common/Typography/Typography';
import ButtonLink from '../../../../_new_components/common/Buttons/ButtonLink/ButtonLink';

import * as styles from './Megamenu.module.css';

interface Props {
    /** The navigation tree to be rendered */
    nodes: {
        id: string;
        title: string;
        level: string;
        url: string;
        parent: {
            id: string;
        };
        element: {
            slug: string;
        };
        type: string;
        children: {
            id: string;
            title: string;
            type: string;
            url: string;
        }[];
    }[];

    conditions: {
        id: string;
        treatmentCount: number;
        slug: string;
    }[];

    className: string;
}

const MegamenuTreatments = ({ nodes = [], conditions = [], className = '' }: Props) => {
    const timeout = useRef<NodeJS.Timeout | undefined>();

    const handleButtonClick = () => {
        document.body.classList.add('_js-nav-megamenu-cancel');
        timeout.current = setTimeout(() => {
            document.body.classList.remove('_js-nav-megamenu-cancel');
        }, 500);
    };

    useEffect(
        () => () => {
            // On unmount clear timeout
            if (timeout.current) {
                document.body.classList.remove('_js-nav-megamenu-cancel');
                clearTimeout(timeout.current);
            }
        },
        []
    );

    return (
        <div className={`p-200 space-y-200 ${styles.megamenu} ${className}`}>
            <div className="flex justify-between items-center">
                <Typography typeset="heading">Popular Treatment</Typography>
                <div className="flex gap-100">
                    <ButtonLink variant="primary" to="/treatments" title="View all treatments" onClick={handleButtonClick}>
                        View all treatments
                    </ButtonLink>
                    <ButtonLink variant="secondary" to="/conditions" title="View all conditions" onClick={handleButtonClick}>
                        View all conditions
                    </ButtonLink>
                </div>
            </div>
            <ul className="grid grid-cols-4 gap-200">
                {nodes.map((node) => {
                    const condition = conditions.find((c) => c.slug === node.element.slug);
                    const title = `See all ${node.title} Treatments`;

                    return (
                        <li key={node.id} className="space-y-050">
                            <CraftMenuLink
                                title={title}
                                menuNode={node}
                                className={`${styles.megamenuGroupHeader} ${styles.megamenuGroupHeaderBorder}`}
                            >
                                <Typography typeset="heading">
                                    {node.title} <Typography color="quiet">({condition ? condition.treatmentCount : 0})</Typography>
                                </Typography>
                                <BaseIcon size="xxsmall">
                                    <IconChevronRight />
                                </BaseIcon>
                            </CraftMenuLink>
                            {node.children ? (
                                <ul className="space-y-050">
                                    {node.children.map((child) => (
                                        <Typography as="li" key={child.id} className="hover:text-content-highlight" size="090">
                                            <CraftMenuLink title={`View ${child.title}`} menuNode={child}>
                                                {child.title}
                                            </CraftMenuLink>
                                        </Typography>
                                    ))}
                                </ul>
                            ) : null}
                            <CraftMenuLink title={title} menuNode={node} className="inline-block">
                                <Typography color="highlight" className="underline hover:no-underline">
                                    View all
                                </Typography>
                            </CraftMenuLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default MegamenuTreatments;
