import React from 'react';

import Attention from '../../../common/Attention/Attention';

/**
 * Notification to the user to say that the treatment the would like is already in their basket.
 */
const AlreadyInBasketNotification = () => (
    <Attention heading="This treatment is already in your basket" type="warning">
        <p>You can checkout with this treatment or change the quantity from the basket</p>
    </Attention>
);

export default AlreadyInBasketNotification;
