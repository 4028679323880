import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash.get';

import BaseIcon from '@components/_new_components/common/Icons/BaseIcon/BaseIcon';
import IconX from '@components/_ui/_blocks/Icons/Social/IconX';
import IconFacebook from '@components/_ui/_blocks/Icons/Social/IconFacebook';
import IconLinkedIn from '@components/_ui/_blocks/Icons/Social/IconLinkedIn';

import * as styles from '../../Footer/Footer.module.css';

const SocialIcons = () => {
    const data = useStaticQuery(graphql`
        {
            craftcms {
                globalSets(handle: "sitewideContent") {
                    ... on CraftCMS_sitewideContent_GlobalSet {
                        contactTwitterUrl
                        contactFacebookUrl
                        contactLinkedinUrl
                    }
                }
            }
        }
    `);

    // const socialLinks = (data.craftcms.globalSets) ? data.craftcms.globalSets[0] : null;
    const socialLinks = get(data, 'craftcms.globalSets[0]', false);

    if (!socialLinks) return null;

    return (
        <div className={styles.hippo_footer_socials}>
            <a
                href={socialLinks.contactFacebookUrl}
                title="Visit our Facebook page"
                aria-label="Visit our Facebook page"
                rel="nofollow noreferrer"
                target="_blank"
            >
                <BaseIcon viewBox="0 0 24 24">
                    <IconFacebook />
                </BaseIcon>
            </a>
            <a
                href={socialLinks.contactTwitterUrl}
                title="View our Twitter account"
                aria-label="View our Twitter account"
                rel="nofollow noreferrer"
                target="_blank"
                className={styles.footer.textColor}
            >
                <BaseIcon viewBox="0 0 24 24">
                    <IconX />
                </BaseIcon>
            </a>
            <a
                href={socialLinks.contactLinkedinUrl}
                title="Connect with us on LinkedIn"
                aria-label="Connect with us on LinkedIn"
                rel="nofollow noreferrer"
                target="_blank"
            >
                <BaseIcon>
                    <IconLinkedIn />
                </BaseIcon>
            </a>
        </div>
    );
};

export default SocialIcons;
