import React, { Fragment } from 'react';

import Typography from '../../Typography/Typography';

interface Props {
    id?: string;
    label?: string;
    description?: string | React.ReactNode;
    optional?: boolean;
}

/**
 * Label, description and optional flag for text and number input components.
 */
const Label = ({ id, label, description, optional }: Props) => {
    if (!label && !description) { // If there is no label or description.
        return null;
    }

    return (
        <Fragment>
            {label ? (
                <Typography
                    as="label"
                    typeset="heading"
                    size="090"
                    lineHeight="none"
                    htmlFor={id}
                    className="flex gap-100 items-center justify-between"
                >
                    {label}
                    {optional ? (
                        <Typography as="span" size="080" lineHeight="none" color="quiet">
                            (optional)
                        </Typography>
                    ) : null}
                </Typography>
            ) : null}
            {description ? (
                <Typography as="div" size="070" color="quiet">
                    {description}
                </Typography>
            ) : null}
        </Fragment>
    );
};

export default Label;
