import React from 'react';

import Attention from '../../../common/Attention/Attention';

/**
 * Notification to the user that the treatment is a prescription only treatment and that a prescription is
 * required to purchase.
 */
const PrivatePrescriptionNotification = () => (
    <Attention heading="Private prescription required">
        <p>
            You need a private prescription from your GP or private clinic to order this treatment. Any orders placed where we have{' '}
            <strong>not received</strong> a valid prescription will be refunded.
        </p>
    </Attention>
);

export default PrivatePrescriptionNotification;
