import dayjs from 'dayjs';

import { TRACKING_ENABLED, convertStrToBool, getDeploymentEnvironment } from '../../config/config';
import { getCookie } from '../../helpers/cookie';

interface AnalyticsLogEntry {
    identifier: string;
    eventName: string;
    timestamp: string;
}

export interface WindowAnalytics {
    log: AnalyticsLogEntry[];
}

/**
 * Base analytics class that allows us to:
 * - Check if cooke consent has been given.
 * - Log analytics events for dev purposes.
 */
class Analytics {
    readonly isAnalyticsEnabled = () => TRACKING_ENABLED && convertStrToBool(getCookie('cookie_consent'));
    private identifier = '';

    constructor(identifier: string) {
        this.identifier = identifier;

        // If there's no log set, set it to an empty array.
        if (!window?.analytics?.log) {
            window.analytics = {
                log: [],
            };
        }
    }

    // Function to add triggered analytics events to a log.
    readonly logEvent = (eventName: string) => {
        if (typeof window === 'undefined' || getDeploymentEnvironment() === 'production') {
            return;
        }

        window.analytics!.log.push({
            identifier: this.identifier,
            eventName,
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        });
    };
}

export default Analytics;
