import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import Basket from '../../components/Basket/Basket';
import SidebarNavigation from '../../components/Navigation/CraftMenuTypes/SidebarNavigation/SidebarNavigation';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Navigation/Header/Header';
import AssessmentWrapper from '../../components/Assessment/AssessmentWrapper';
import Confirmation from '../../components/_ui/Confirmation/Confirmation';
import DrawerNavigation from '../../components/_new_components/DrawerNavigation/DrawerNavigation';
import SessionActivity from '../../components/Authentication/SessionActivity/SessionActivity';
import AffiliateBanner from '../../modules/affiliate/components/AffiliateBanner';
import SiteBanner from '../../modules/siteBanner/components/SiteBanner/SiteBanner';

const SearchOverlay = loadable(() => import('@components/Search/SearchOverlay/SearchOverlay'));
const FontEmbed = loadable(() => import('@components/_ui/FontEmbed'));
const CookiesNotification = loadable(() => import('@components/CookiesNotification'));

/**
 * Layout for the Pay Per Click (PPC) pages.
 * @param {object} props
 * @returns {ReactElement}
 */
const PPCLayout = ({ children }) => (
    <Fragment>
        <FontEmbed />
        <AffiliateBanner />
        <Header disableSearch disableAccount disableBasket disableBurgerMenu disableMainNav />
        <SiteBanner />
        <main>{children}</main>
        <Footer />
        <Basket />
        <DrawerNavigation />
        <SidebarNavigation />
        <CookiesNotification />
        <SearchOverlay />
        <AssessmentWrapper />
        <Confirmation />
        <SessionActivity />
    </Fragment>
);

PPCLayout.propTypes = {
    /**
     * The page content components.
     */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default PPCLayout;
