/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';

import useLockViewportScrolling from '../../../hooks/useLockViewportScrolling';
import useEscKey from '../../../hooks/useEscKey';

import BaseIcon from '../../_new_components/common/Icons/BaseIcon/BaseIcon';
import IconCross from '../_blocks/Icons/Navigation/IconCross';
import Overlay from '../_blocks/Overlay/Overlay';
import Typography from '../../_new_components/common/Typography/Typography';
import Button from '../../_new_components/common/Buttons/Button/Button';
import IconArrowLeft from '../_blocks/Icons/Navigation/IconArrowLeft';

import * as styles from './Flyout.module.css';

const baseDirectionClassName = {
    top: styles.directionTop,
    bottom: styles.directionBottom,
    left: styles.directionLeft,
    right: styles.directionRight,
};

const directionClassNames = {
    top: {
        enter: styles.animateInVertically,
        enterActive: styles.animateInVertically,
        enterDone: styles.animateInVertically,
        exit: styles.exitTransition,
    },
    bottom: {
        enter: styles.animateInVertically,
        enterActive: styles.animateInVertically,
        enterDone: styles.animateInVertically,
        exit: styles.exitTransition,
    },
    left: {
        enter: styles.animateInHorizontally,
        enterActive: styles.animateInHorizontally,
        enterDone: styles.animateInHorizontally,
        exit: styles.exitTransition,
    },
    right: {
        enter: styles.animateInHorizontally,
        enterActive: styles.animateInHorizontally,
        enterDone: styles.animateInHorizontally,
        exit: styles.exitTransition,
    },
};

const timeout = {
    enter: 600,
    exit: 400,
};

interface Props {
    /** To determine whether the Flyout is open or not. */
    isOpen: boolean;
    /** Applies a title to the Flyout */
    title: string | React.ReactNode;
    /** Children that are rendered inside the Flyout. */
    children: React.ReactNode;
    /** Function to onclose the Flyout. */
    onClose?: () => void;
    /** Show or hide the overlay layer when flyout is open */
    disableOverlay?: boolean;
    direction?: 'left' | 'right' | 'top' | 'bottom';
    onBack?: () => void;
}

const Flyout = ({ isOpen, direction = 'left', disableOverlay, title, children, onClose, onBack }: Props) => {
    useLockViewportScrolling(!!(isOpen && !disableOverlay));

    useEscKey(!disableOverlay ? onClose : null);

    const className = `${styles.flyout} ${baseDirectionClassName[direction]}`;
    const classNames = directionClassNames[direction];

    return (
        <Fragment>
            {!disableOverlay ? (
                <Overlay className={`${styles.overlay} !bg-opacity-15`} show={isOpen}>
                    <div role="button" onClick={onClose} />
                </Overlay>
            ) : null}
            <CSSTransition in={isOpen} mountOnEnter unmountOnExit timeout={timeout} className={className} classNames={classNames}>
                <aside>
                    <header className={styles.header}>
                        {onBack ? (
                            <Button variant="none" size="none" title="Click to go back" className={styles.backButton} onClick={onBack}>
                                <BaseIcon size="small">
                                    <IconArrowLeft />
                                </BaseIcon>
                            </Button>
                        ) : null}
                        <Typography as="h3" typeset="heading" size="090" lineHeight="1000" className="flex-grow">
                            {title}
                        </Typography>
                        <button type="button" title="Close" onClick={onClose} className={styles.close}>
                            <BaseIcon size="small">
                                <IconCross />
                            </BaseIcon>
                        </button>
                    </header>
                    <div className={styles.inner}>{children}</div>
                </aside>
            </CSSTransition>
        </Fragment>
    );
};

export default Flyout;
