import React from 'react';

import CraftMenuLink from '../../../CraftMenuLink/CraftMenuLink';
import Typography from '../../../../_new_components/common/Typography/Typography';

import * as styles from './MainNavigationDropdown.module.css';

interface MainNavigationDropdownProps {
    node: {
        title: string;
        children: {
            element: {
                id: string;
                slug: string;
                parent: {
                    slug: string;
                } | null;
            };
            type: string;
            url: string;
            title: string;
        }[];
    };
}

/**
 * If a main navigation item has children but there's no mega menu, this is shown.
 */
const MainNavigationDropdown = ({ node }: MainNavigationDropdownProps) => (
    <ul className={styles.dropdown}>
        {node.children.map((child) => (
            <Typography key={child.element.id} as="li" typeset="note" size="090">
                <CraftMenuLink menuNode={child} className={styles.dropdownItem}>
                    {child.title}
                </CraftMenuLink>
            </Typography>
        ))}
    </ul>
);

export default MainNavigationDropdown;
