import React, { useMemo } from 'react';

import { BasketItem } from '../../../types/api/basket/BasketItem';
import formatQuantityLabel from '../../Treatment/helpers/formatQuantityLabel';

import QuantityPricing from '../../_new_components/TreatmentSelector/QuantityPricing/QuantityPricing';
import Selector from '../../_new_components/TreatmentSelector/Selector/Selector';

export interface Props {
    item: BasketItem;
    onChange: (e: any) => void;
    selectedQuantity?: number;
}

const RadioSelector = ({ item, onChange, selectedQuantity = 0 }: Props) => {
    const { highest_unit_cost: highestUnitCost, quantities } = item;
    const orderedQuantities = useMemo(() => quantities.sort((a, b) => a.quantity - b.quantity), [quantities]);

    const handleChange = (newQuantity: number) => {
        onChange(item?.quantities.find((q) => q.quantity === newQuantity) || null);
    };

    return (
        <Selector value={selectedQuantity} onChange={handleChange} data-testid="regular-quantity">
            {orderedQuantities.map((quantity) => {
                const key = `${item.product_name}-${quantity.quantity}`;
                const saving = highestUnitCost * quantity.quantity - quantity.price;

                return (
                    <Selector.Card key={key} value={quantity.quantity} title={`Select ${quantity.label}`}>
                        {() => (
                            <QuantityPricing
                                unitCost={quantity.unit_cost}
                                label={formatQuantityLabel(quantity)}
                                price={quantity.price}
                                saving={saving}
                            />
                        )}
                    </Selector.Card>
                );
            })}
        </Selector>
    );
};

export default RadioSelector;
