import React from 'react';
import clx from 'classnames';

import formatQuantityLabel from '../../../../Treatment/helpers/formatQuantityLabel';
import formatPrice from '../../../../../helpers/formatPrice';
import { useSelectedQuantityContext, useSelectedVariantContext } from '../../TreatmentSelectorContext/TreatmentSelectorContext';

import Typography from '../../../common/Typography/Typography';
import TreatmentPageButton from '../../SelectorButtons/TreatmentPageButton/TreatmentPageButton';

import * as styles from './BasketPreviewCard.module.css';

type TreatmentPageButtonProps = React.ComponentProps<typeof TreatmentPageButton>;
type Props = Pick<TreatmentPageButtonProps, 'conditionSlug' | 'onClick' | 'loading'>;

/**
 * A small card with information about the currently selected variant and quantity.
 */
const BasketPreviewCard = ({ conditionSlug, loading, onClick }: Props) => {
    const { name } = useSelectedVariantContext();
    const selectedQuantity = useSelectedQuantityContext();

    const saving = selectedQuantity?.saving;
    const price = selectedQuantity?.price;

    const titleClassName = clx(styles.treatmentName, saving && price ? 'mb-0125' : '');

    return (
        <aside className={styles.card}>
            <div className="min-w-0">
                <Typography as="h4" typeset="note" size="080" className={titleClassName}>
                    {name}
                </Typography>
                {selectedQuantity ? (
                    <Typography as="div" typeset="note" size="070" color="quiet">
                        {formatQuantityLabel(selectedQuantity)}
                    </Typography>
                ) : null}
                {saving && price ? (
                    <div className="flex gap-050 items-center">
                        {saving ? (
                            <Typography typeset="note" size="070" color="accent">
                                Save: {formatPrice(saving)}
                            </Typography>
                        ) : null}
                        {price ? (
                            <Typography typeset="note" size="070" color="highlight">
                                Total: {formatPrice(price)}
                            </Typography>
                        ) : null}
                    </div>
                ) : null}
            </div>
            <TreatmentPageButton
                size="small"
                className="flex-shrink-0"
                onClick={onClick}
                conditionSlug={conditionSlug}
                loading={loading}
                type="button"
            />
        </aside>
    );
};

export default BasketPreviewCard;
