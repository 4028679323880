import React from 'react';

import Selector from '../Selector/Selector';
import {
    useSelectedVariantContext,
    useTreatmentSelectorUpdaterContext,
    useVariantsContext,
} from '../TreatmentSelectorContext/TreatmentSelectorContext';
import Typography from '../../common/Typography/Typography';
import BaseIcon from '../../common/Icons/BaseIcon/BaseIcon';
import IconCheckmark from '../../../_ui/_blocks/Icons/Navigation/IconCheckmark';

/**
 * Used to select the desired variant from a treatment.
 */
const VariantSelector = () => {
    const variants = useVariantsContext();
    const selectedVariant = useSelectedVariantContext();
    const { updateVariant } = useTreatmentSelectorUpdaterContext();

    const handleChange = (value: number) => {
        updateVariant(variants.find((variant) => variant.id === value)!);
    };

    // If there is only one variant to select, we don't need to show the selector.
    if (variants.length === 1) {
        return null;
    }

    return (
        <Selector label="Select treatment" value={selectedVariant.id} onChange={handleChange}>
            {variants.map((variant) => (
                <Selector.Card key={variant.id} value={variant.id} title={`Select ${variant.name}`}>
                    {({ checked }) => (
                        <div className="flex gap-100 justify-between items-center">
                            <Typography size="090" weight={checked ? '800' : '400'}>
                                {variant.name}
                            </Typography>
                            {checked ? (
                                <BaseIcon size="xsmall" color="highlight">
                                    <IconCheckmark />
                                </BaseIcon>
                            ) : null}
                        </div>
                    )}
                </Selector.Card>
            ))}
        </Selector>
    );
};

export default VariantSelector;
