import { calculateDifferenceAsPercentage } from '../../../../../../helpers/maths';
import { ProductsQuantity } from '../../../../../../types/api/products/ProductsQuantity';

/**
 * Gets the percentage saving between the largest and smallest quantities.
 * NOTE: The quantities should be pre sorted smallest to largest.
 */
const getPercentageSaving = (quantities: ProductsQuantity[] = []) => {
    if (quantities.length < 2) {
        return 0;
    }

    const smallestQuantity = quantities[0];
    const largestQuantity = quantities.at(-1)!;

    const percentage = calculateDifferenceAsPercentage(largestQuantity.unit_cost, smallestQuantity.unit_cost);

    return Math.floor(percentage);
};

export default getPercentageSaving;
