import React from 'react';

import * as styles from './LoadingSkeleton.module.css';

/**
 * Loading skeleton shown whilst treatment selector is loading.
 */
const LoadingSkeleton = () => {
    const titleClassName = `skeleton-animation ${styles.title}`;
    const tagClassName = `skeleton-animation ${styles.tag}`;
    const labelClassName = `skeleton-animation ${styles.label}`;
    const optionClassName = `skeleton-animation ${styles.option}`;

    return (
        <div className="space-y-150">
            <div className="space-y-050">
                <div className="flex gap-100 space-between items-center">
                    <div className={titleClassName} />
                    <div className={tagClassName} />
                </div>
                <div className="flex gap-100 justify-between">
                    <div className={labelClassName} style={{ maxWidth: '9.5rem' }} />
                    <div className={labelClassName} style={{ maxWidth: '4.75rem' }} />
                </div>
            </div>
            <div className="space-y-050">
                <div className={optionClassName} />
                <div className={optionClassName} />
                <div className={optionClassName} />
            </div>
            <div className="space-y-050">
                <div className={optionClassName} />
                <div className={optionClassName} />
                <div className={optionClassName} />
            </div>
            <div className={`skeleton-animation ${styles.button}`} />
        </div>
    );
};

export default LoadingSkeleton;
