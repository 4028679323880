import React from 'react';

import Attention from '../../../common/Attention/Attention';
import { GenderKeys, HumanReadableGenders } from '../../../../../types/api/Gender';

interface Props {
    treatmentGender: GenderKeys;
    patientGender: GenderKeys;
}

/**
 * Notification to the user to say that the treatment the would like is not suitable for their given gender.
 */
const GenderNotification = ({ treatmentGender, patientGender }: Props) => {
    const heading = `Sorry, we are only able to provide this treatment for ${HumanReadableGenders[treatmentGender]} patients`;

    return (
        <Attention heading={heading} type="warning">
            <p>
                You have indicated that your biological sex is
                <strong> {HumanReadableGenders[patientGender]}</strong>. Please create a separate account if this treatment is required for
                a different person to the individual registered on this account. If you believe that there is an issue with the biological
                sex recorded on your account, please contact our support team.
            </p>
        </Attention>
    );
};

export default GenderNotification;
