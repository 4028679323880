import React, { Fragment } from 'react';

import { BasketItem } from '../../../../../../types/api/basket/BasketItem';
import useManageBasketItems from '../../../../../../data/context/basketContext/hooks/useManageBasketItems';
import { DeleteCardOverlay, DeleteCardOverlayTrigger } from '../../../../../_ui/Card/DeleteCardOverlay/DeleteCardOverlay';

import IconBlocked from '../../../../../_ui/_blocks/Icons/IconBlocked';
import Tag from '../../../../../_new_components/common/Tag/Tag';
import BaseIcon from '../../../../../_new_components/common/Icons/BaseIcon/BaseIcon';
import Typography from '../../../../../_new_components/common/Typography/Typography';

import * as styles from '../../BasketItem.module.css';

export interface Props {
    item: BasketItem;
}

const UnavailableBasketItem = ({ item }: Props) => {
    const { deleteItems } = useManageBasketItems();

    return (
        <DeleteCardOverlay
            className={styles.item}
            title={item.treatment}
            description="Are you sure? This will also delete the consultation for this treatment."
            onDelete={() => deleteItems([{ id: item.id }])}
        >
            <Fragment>
                <div data-testid="unavailable_basket_item" className="flex items-start gap-100">
                    <div className={`${styles.image} ${styles.unavailableImage}`}>
                        <BaseIcon>
                            <IconBlocked />
                        </BaseIcon>
                    </div>
                    <div className="flex-grow space-y-050">
                        <Tag size="medium" color="error">
                            Item unavailable
                        </Tag>
                        <Typography as="h6" typeset="note" size="080" weight="800" lineThrough>
                            {item.treatment}
                        </Typography>
                        <Typography as="p" size="080" color="quiet">
                            This item is no longer available and will be removed from your order on checkout.
                        </Typography>
                    </div>
                </div>
                <DeleteCardOverlayTrigger title={`Remove ${item.treatment} from your basket`} />
            </Fragment>
        </DeleteCardOverlay>
    );
};

export default UnavailableBasketItem;
