import { useEffect } from 'react';

const useLockViewportScrolling = (locked) => {
    const getHtmlElement = () => document.querySelector('html');

    useEffect(() => {
        if (locked) {
            getHtmlElement().style.overflow = 'hidden';
            return;
        }

        getHtmlElement().style.removeProperty('overflow');
    }, [locked]);

    useEffect(
        () => () => {
            getHtmlElement().style.removeProperty('overflow');
        },
        []
    );
};

export default useLockViewportScrolling;
