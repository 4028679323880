import React from 'react';

const IconX = () => (
    <path
        d="M0.0585144 0.800049L9.32465 13.155L0 23.2H2.0986L10.2623 14.4054L16.8584 23.2H24L14.2125 10.1502L22.8918 0.800049H20.7932L13.2748 8.89972L7.20014 0.800049H0.0585144ZM3.14467 2.34155H6.42555L20.9134 21.6583H17.6325L3.14467 2.34155Z"
        fill="inherit"
    />
);

export default IconX;
