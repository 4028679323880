import React from 'react';
import clx from 'classnames';

import IconCross from '@components/_ui/_blocks/Icons/Navigation/IconCross';
import BaseIcon from '../../_new_components/common/Icons/BaseIcon/BaseIcon';
import IconStatusWarning from './Icons/Status/IconStatusWarning';
import IconStatusInfo from './Icons/Status/IconStatusInfo';
import IconStatusSuccess from './Icons/Status/IconStatusSuccess';

import * as styles from './Flash.module.css';
import Typography from '../../_new_components/common/Typography/Typography';

export enum Types {
    info = 'info',
    success = 'success',
    warning = 'warning',
    error = 'error',
}

interface Props {
    type: keyof typeof Types;
    message: string;
    onClose?: () => void;
    important?: boolean;
}

const Flash = ({ type, message, onClose = undefined, important }: Props) => {
    const classNames = clx(styles.flash, styles[Types[type]]);

    return (
        <div className={classNames}>
            <BaseIcon size="small">
                {/* TODO: findIcon is causing a preact error in this component, think it's to do with loadable returning undefined  */}
                {/* {findIcon(`icon-${type}`)} */}
                {type === 'warning' || type === 'error' ? <IconStatusWarning /> : null}
                {type === 'warning' || type === 'error' ? <IconStatusWarning /> : null}
                {type === 'info' ? <IconStatusInfo /> : null}
                {type === 'success' ? <IconStatusSuccess /> : null}
            </BaseIcon>
            <Typography as="p" typeset={important ? 'heading' : undefined} size="090">
                {message}
            </Typography>
            {onClose ? (
                <button type="button" className="button--transparent" onClick={onClose} title="Close message notification">
                    <BaseIcon size="small">
                        <IconCross />
                    </BaseIcon>
                </button>
            ) : null}
        </div>
    );
};

export default Flash;
