// extracted by mini-css-extract-plugin
export var company_information = "Z-O06Cn-DvAR8q+Nmmucog==";
export var container = "AUhvZP84rKEgu57mHsmfdQ==";
export var fluid = "KJmt+Ao6J25zDPoH40YSAw==";
export var footer = "UZpYCUlNUvW6yZkIT7HUOQ==";
export var footer_company_information = "L2n1jXK53BxNy1zq9Rpwaw==";
export var footer_pharmacist = "d6AldxV-7zyDkNs10teqiw==";
export var footer_section = "wswfzuNQzIx8vMedTOXkIg==";
export var hippo_footer_socials = "V1BukZNQTpukjaX7ammDcw==";
export var logo = "ADEigPyBXPpg5yGstPFu4g==";
export var space_y_200 = "_4UXUcQ43g44WohigjOfNew==";