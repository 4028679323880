// extracted by mini-css-extract-plugin
export var avatar = "WXMceIZ2PDHDNnSdBJcomw==";
export var borderForeground = "_5lKZkkp1VgRBxBSn9EvEaA==";
export var full = "FuX5W-B4Oedm5IIV0S+hyg==";
export var halfPillDown = "JUg23qb0fhOo513proUyGg==";
export var halfPillUp = "y6KO948i87Xd7SoxoP7YrQ==";
export var large = "SE6K6RiBjWYWY+u4nigfxQ==";
export var medium = "_1p75IgXMTWIDNdtGdevrRQ==";
export var pill = "ik1eedmgLkXjUoLBw09VlA==";
export var small = "bDhHSNIXnuXPHZs3x12iMQ==";
export var xlarge = "gHTYW77ztNOM9ojG3f5BWw==";