import React from 'react';

const IconFacebook = () => (
    <path
        d="M17.6481 13.5L18.3222 9.15656H14.1072V6.33797C14.1072 5.14969 14.696 3.99141 16.5838 3.99141H18.5V0.293438C18.5 0.293438 16.7611 0 15.0985 0C11.6274 0 9.35847 2.08031 9.35847 5.84625V9.15656H5.5V13.5H9.35847V24H14.1072V13.5H17.6481Z"
        fill="inherit"
    />
);

export default IconFacebook;
