// extracted by mini-css-extract-plugin
export var bestSeller = "zGKt3ZDX8Zj3qJnBz6-iBg==";
export var borderRadiusFull = "qSmLz9Qv3c0fb1mlit3yXQ==";
export var borderRadiusSmall = "BOhYGbjRzN0OqGsYfdviUA==";
export var bundlePack = "mQqzsW3KiXlafTrtKBBKHA==";
export var error = "RWSn1bJJCQuCwmYY1RZkag==";
export var info = "_4x4MtxYrpLGD+RyLU2dO0A==";
export var large = "-eGSN2eV9HN1dtW-ayhYOA==";
export var medium = "iFtRi3ibPOTBT4QdtcpSDg==";
export var multiBuyOffer = "_7Dbar9XW2Jq9bzBs6JV2Ug==";
export var newPackaging = "xNcHsuLnwaQ-OrBLOsmryw==";
export var newProduct = "_6I9VGRzmWaZhneKZaVZKaw==";
export var regular = "lGrkrnoq9XvRM4vUrNhkzA==";
export var sale = "Q5VAHQfc6oYBfgF5RwXAnw==";
export var specialOffer = "q+epn8UWHBIREhCgHSwaXA==";
export var success = "pAhdw4xxslUUXZe5MHAxbw==";
export var tag = "bx-v4ud9le-HPCEFqRjtlw==";
export var warning = "RIhnBukN2p5Rnvbyux4uQw==";