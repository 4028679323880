import React from 'react';

import { useBasketContext } from '../../../../../../data/context/basketContext/basketContext';

import Button from '../../../../common/Buttons/Button/Button';

type ButtonProps = React.ComponentProps<typeof Button>;

const OpenBasketButton = (props: Omit<ButtonProps, 'children' | 'title'>) => {
    const { setBasketIsOpen } = useBasketContext();

    const openBasket = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setBasketIsOpen((s: boolean) => !s);
    };

    return (
        <Button {...props} onClick={openBasket} title="Open your basket">
            Open basket
        </Button>
    );
};

export default OpenBasketButton;
