import { useMemo } from 'react';

import { ProductVariant } from '../../../../../types/api/products/Product';
import { useBasketContext } from '../../../../../data/context/basketContext/basketContext';
import { useAuthContext } from '../../../../../data/context/authContext';
import { ProductsQuantity } from '../../../../../types/api/products/ProductsQuantity';
import getBasketBlockers from '../../../TreatmentSelector/TreatmentSelectorContext/hooks/useBasketBlockers/helpers/getBasketBlockers/getBasketBlockers';
import { AlreadyInBasketBlocker } from '../../../TreatmentSelector/TreatmentSelectorContext/hooks/useBasketBlockers/helpers/getAlreadyInBasketBlocker/getAlreadyInBasketBlocker.js';
import { BlockedByBasketBlocker } from '../../../TreatmentSelector/TreatmentSelectorContext/hooks/useBasketBlockers/helpers/getBlockedByBasketBlocker/getBlockedByBasketBlocker.js';
import { GenderBlocker } from '../../../TreatmentSelector/TreatmentSelectorContext/hooks/useBasketBlockers/helpers/getGenderBlocker/getGenderBlocker.js';
import { OutOfStockBlocker } from '../../../TreatmentSelector/TreatmentSelectorContext/hooks/useBasketBlockers/helpers/getOutOfStockBlocker/getOutOfStockBlocker.js';

interface QuantityNotAvailableBlocker {
    type: 'QUANTITY_NOT_AVAILABLE';
}

interface UnavailableBlocker {
    type: 'UNAVAILABLE';
}

// TODO: Get this to use ReturnType<typeof getBasketBlockers> instead of manually defining the original blockers.
type RefillBlockers = Array<
    OutOfStockBlocker | GenderBlocker | BlockedByBasketBlocker | AlreadyInBasketBlocker | QuantityNotAvailableBlocker | UnavailableBlocker
>;

/**
 * Hook to get refill blockers that are stopping an item being added to the basket.
 */
const useRefillBlockers = (variant: ProductVariant | null, quantity: ProductsQuantity | null) => {
    const { basket } = useBasketContext();
    const { patient } = useAuthContext();

    const blockers: RefillBlockers = useMemo(() => {
        if (!variant) {
            return [
                {
                    type: 'UNAVAILABLE',
                },
            ];
        }

        const basketBlockers = getBasketBlockers(variant, basket, patient) as RefillBlockers;

        if (!quantity) {
            basketBlockers.push({
                type: 'QUANTITY_NOT_AVAILABLE',
            });
        }

        return basketBlockers;
    }, [variant, quantity, basket, patient]);

    return blockers;
};

export default useRefillBlockers;
