import React from 'react';

import { useSelectedVariantContext } from '../../../TreatmentSelectorContext/TreatmentSelectorContext';

import ButtonLink from '../../../../common/Buttons/ButtonLink/ButtonLink';

type ButtonLinkProps = React.ComponentProps<typeof ButtonLink>;

interface Props extends Omit<ButtonLinkProps, 'children' | 'title' | 'to'> {
    conditionSlug: string;
}

const FindAlternativesButton = ({ conditionSlug, ...rest }: Props) => {
    const {
        name,
        out_of_stock_button_text: outOfStockButtonText,
        out_of_stock_button_url: outOfStockButtonUrl,
    } = useSelectedVariantContext();

    return (
        <ButtonLink {...rest} to={outOfStockButtonUrl || `/${conditionSlug}`} title={`Find an alternative to ${name}`}>
            {outOfStockButtonText || 'Find alternatives'}
        </ButtonLink>
    );
};

export default FindAlternativesButton;
