import React, { Fragment, useState } from 'react';
import { navigate } from 'gatsby';

import useSiteMetadata from '../../../../data/staticQueryHooks/useSiteMetaData';
import { useConsultationLauncherContext } from '../../../../data/context/consultationContext/consultationLauncherContext';

import SaveBasketConfirmation from '../SaveBasketConfirmation/SaveBasketConfirmation';
import Overlay from '../../../_ui/_blocks/Overlay/Overlay';
import Typography from '../../../_new_components/common/Typography/Typography';
import formatPrice from '../../../../helpers/formatPrice';
import BaseIcon from '../../../_new_components/common/Icons/BaseIcon/BaseIcon';
import IconStatusInfo from '../../../_ui/_blocks/Icons/Status/IconStatusInfo';
import Tag from '../../../_new_components/common/Tag/Tag';
import Button from '../../../_new_components/common/Buttons/Button/Button';

import * as styles from './BasketSummary.module.css';
import Hr from '../../../_new_components/common/Hr/Hr';

export interface Props {
    basketSubtotal: number;
}

const BasketSummary = ({ basketSubtotal }: Props) => {
    const [showOverlay, setShowOverlay] = useState(false);

    const { minimumDeliveryCost } = useSiteMetadata();
    const { clearConsultation } = useConsultationLauncherContext();

    const basketTotal = formatPrice(basketSubtotal + parseInt(minimumDeliveryCost));

    const handleSaveBasketOpen = () => setShowOverlay(true);
    const handleSaveBasketClose = () => setShowOverlay(false);
    const handleCheckoutClick = () => {
        clearConsultation();
        navigate('/checkout');
    };

    return (
        <Fragment>
            <div className={styles.basketSummary}>
                <div className="flex justify-between items-center">
                    <Typography as="h6" typeset="heading" size="090">
                        Subtotal
                    </Typography>
                    <Typography typeset="heading" size="090" color="price">
                        {formatPrice(basketSubtotal)}
                    </Typography>
                </div>
                <Hr />
                <div className="flex items-center justify-between gap-100">
                    <Typography as="h6" typeset="heading" size="090">
                        Delivery estimate
                    </Typography>
                    {basketSubtotal > 4000 ? (
                        <Tag size="medium" color="info">
                            Free
                        </Tag>
                    ) : (
                        <Typography as="div" typeset="heading" size="090" color="price">
                            <Typography size="070">from</Typography> {formatPrice(minimumDeliveryCost)}
                        </Typography>
                    )}
                </div>
                {basketSubtotal < 4000 ? (
                    <Typography as="div" typeset="subheading" size="080" className={styles.freeDeliveryNotice}>
                        <BaseIcon color="highlight" size="small">
                            <IconStatusInfo />
                        </BaseIcon>
                        Free standard delivery on orders over £40
                    </Typography>
                ) : null}
                <Hr />
                <div className="flex items-center justify-between gap-100">
                    <Typography as="h6" typeset="heading" size="090">
                        Order total
                    </Typography>
                    {basketSubtotal < 4000 ? (
                        <Typography as="div" typeset="heading" size="090" color="price">
                            <Typography size="070">from</Typography> {basketTotal}
                        </Typography>
                    ) : (
                        <Typography as="div" typeset="heading" size="090" color="price">
                            {basketTotal}
                        </Typography>
                    )}
                </div>
                <Button
                    data-testid="basket-checkout-button"
                    className="w-full text-center"
                    title="Proceed to checkout"
                    onClick={handleCheckoutClick}
                >
                    Checkout
                </Button>
                <div className="text-center">
                    <Button
                        variant="none"
                        size="none"
                        data-testid="basket-save-for-later-button"
                        title="Save you basket for another time"
                        className={styles.saveForLaterButton}
                        onClick={handleSaveBasketOpen}
                    >
                        Save basket for later
                    </Button>
                </div>
            </div>
            <Overlay className="flex items-center p-100" show={showOverlay} fixedToViewport={false}>
                <SaveBasketConfirmation onClose={handleSaveBasketClose} />
            </Overlay>
        </Fragment>
    );
};

export default BasketSummary;
