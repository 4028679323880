import React from 'react';
import clx from 'classnames';

import BaseIcon from '../BaseIcon/BaseIcon';

import * as styles from './LoadingIcon.module.css';

interface Props extends Omit<React.ComponentProps<typeof BaseIcon>, 'children'> {
    animate?: boolean;
}

const LoadingIcon = ({ className, ...rest }: Props) => {
    const classNames = clx(styles.icon, styles.animate, className);

    return (
        <BaseIcon className={classNames} {...rest}>
            <path d="M24 -1.04907e-06C29.7122 -1.29876e-06 35.2372 2.03741 39.5818 5.74605C43.9265 9.45468 46.8058 14.5913 47.7025 20.2328C48.5991 25.8742 47.4542 31.6505 44.4735 36.5234C41.4928 41.3963 36.8718 45.0463 31.4411 46.8173C26.0103 48.5884 20.1259 48.3643 14.8455 46.1855C9.56516 44.0066 5.23507 40.0158 2.63352 34.9304C0.031973 29.845 -0.670424 23.9983 0.652575 18.4414C1.97557 12.8845 5.23721 7.98165 9.85125 4.6141L12.681 8.49128C8.98977 11.1853 6.38046 15.1076 5.32206 19.5531C4.26366 23.9987 4.82558 28.676 6.90681 32.7443C8.98805 36.8127 12.4521 40.0053 16.6764 41.7484C20.9007 43.4915 25.6082 43.6707 29.9528 42.2539C34.2975 40.837 37.9943 37.917 40.3788 34.0187C42.7634 30.1204 43.6793 25.4994 42.962 20.9862C42.2447 16.4731 39.9412 12.3637 36.4655 9.39684C32.9898 6.42993 28.5698 4.8 24 4.8L24 -1.04907e-06Z" />
        </BaseIcon>
    );
};

export default LoadingIcon;
