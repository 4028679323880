import React, { createContext, useContext, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';

import BinButton from './BinButton';

import * as styles from './DeleteCardOverlay.module.css';
import IconStatusWarning from '../../_blocks/Icons/Status/IconStatusWarning';
import BaseIcon from '../../../_new_components/common/Icons/BaseIcon/BaseIcon';
import Typography from '../../../_new_components/common/Typography/Typography';
import Button from '../../../_new_components/common/Buttons/Button/Button';

export const animationTypes = {
    all: 'all',
    opacity: 'opacity',
};

const animationClasses = {
    all: styles.animateScale,
    opacity: styles.animateOpacity,
};

export interface DeleteCardOverlayProviderValue {
    overlayOpen: boolean;
    setOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleOpen: () => void;
}

const DeleteOverlayContext = createContext<DeleteCardOverlayProviderValue>({
    handleOpen: () => {},
    overlayOpen: false,
    setOverlayOpen: () => {},
});

/**
 * Hook to be used in components that will allow us access to the values passed into the
 * "DeleteOverlayContext".
 */
const useDeleteOverlayContext = () => {
    const context = useContext(DeleteOverlayContext);

    if (context === undefined) {
        throw new Error('This component must be nested inside a Basket remove overlay component');
    }

    return context;
};

const getHeightAndSpacingValues = (element: HTMLElement) => {
    const initialHeight = element.offsetHeight;

    const style = getComputedStyle(element);
    const paddingTop = style.paddingTop ? parseFloat(style.paddingTop) : 0;
    const paddingBottom = style.paddingBottom ? parseFloat(style.paddingBottom) : 0;
    const borderTop = style.borderTopWidth ? parseFloat(style.borderTopWidth) : 0;
    const borderBottom = style.borderBottomWidth ? parseFloat(style.borderBottomWidth) : 0;

    const borderSpacing = borderTop + borderBottom;

    const spacing = paddingTop + paddingBottom + borderSpacing;
    const heightOnly = initialHeight - spacing;

    return {
        initialHeight,
        heightOnly,
        borderSpacing,
        spacing,
    };
};

export interface DeleteOverlayProps {
    className?: string;
    title?: React.ReactNode;
    description?: React.ReactNode;
    onDelete: ((e: any) => void) | undefined;
    children?: React.JSX.Element | null;
    animationType?: keyof typeof animationTypes;
}

/**
 * Component to show over the top of a card if a bin button is clicked.
 * onDelete is called on the css animation transition end.
 */
export const DeleteOverlay = ({ title, description, onDelete, children, animationType = 'all', className = '' }: DeleteOverlayProps) => {
    const [deleteItem, setDeleteItem] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const originalContainerHeight = useRef(0);

    const { overlayOpen, setOverlayOpen } = useDeleteOverlayContext(); //eslint-disable-line

    /**
     * On click of the bin icon in an item, show the remove options.
     */
    const openOverlay = useCallback(() => {
        const containerHeightAndSpacing = getHeightAndSpacingValues(containerRef.current!);

        // Set and store the original height of the container.
        containerRef.current!.style.height = `${containerHeightAndSpacing.initialHeight}px`;
        originalContainerHeight.current = containerHeightAndSpacing.initialHeight;

        // Get the height of the hidden overlay.
        const overlayHeight = overlayRef.current!.offsetHeight;

        setOverlayOpen(true);

        if (containerHeightAndSpacing.heightOnly > overlayHeight - containerHeightAndSpacing.spacing) {
            overlayRef.current!.style.height = '100%';
            return;
        }

        containerRef.current!.style.height = `${overlayHeight + containerHeightAndSpacing.borderSpacing}px`;
    }, [setOverlayOpen]);

    /**
     * Reset the height of the item using its data attribute and hide the delete overlay.
     */
    const closeOverlay = () => {
        containerRef.current!.style.height = `${originalContainerHeight.current!}px`;
        setTimeout(() => {
            containerRef.current!.style.removeProperty('height');
            overlayRef.current!.style.removeProperty('height');
        }, 500);
        setOverlayOpen(false);
    };

    useEffect(() => {
        if (overlayOpen) openOverlay();
    }, [overlayOpen, openOverlay]);

    const animationClass = animationClasses[animationType] || animationClasses.all;

    return (
        <CSSTransition
            className={`${className} ${styles.heightAnimation}`}
            classNames={{ exit: animationClass }}
            timeout={{ enter: 0, exit: 750 }}
            in={!deleteItem}
            unmountOnExit
            onExited={onDelete}
        >
            <div ref={containerRef}>
                {children}
                <div ref={overlayRef} className={`${styles.overlay} ${overlayOpen ? styles.overlayShow : ''}`}>
                    <div className="flex gap-050">
                        <BaseIcon size="medium" color="error-500" className="flex-shrink-0">
                            <IconStatusWarning />
                        </BaseIcon>
                        <div className="space-y-050">
                            <Typography as="h6" typeset="heading" size="090">
                                {title}
                            </Typography>
                            <Typography as="p" typeset="note" color="quiet" lineHeight="200">
                                {description}
                            </Typography>
                        </div>
                    </div>
                    <div className="flex gap-100 justify-end w-full">
                        <Button variant="none" size="small" title="Cancel removing this item" onClick={() => closeOverlay()}>
                            No, cancel
                        </Button>
                        <Button
                            variant="none"
                            size="small"
                            title="Remove this item"
                            className="confirm"
                            onClick={() => setDeleteItem(true)}
                        >
                            Yes, delete
                        </Button>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export interface DeleteCardOverlayProps {
    children: React.JSX.Element;
    onDelete: ((e: any) => void) | undefined;
    className?: string;
    title?: string;
    description?: string;
}

/**
 * Start of the DeleteCardOverlay context.
 */
export const DeleteCardOverlay = ({ children, onDelete = undefined, ...rest }: DeleteCardOverlayProps) => {
    const [overlayOpen, setOverlayOpen] = useState(false);

    const providerValue = useMemo<DeleteCardOverlayProviderValue>(
        () => ({
            overlayOpen,
            setOverlayOpen,
            handleOpen: () => setOverlayOpen(true),
        }),
        [overlayOpen, setOverlayOpen]
    );

    if (!onDelete) return children;

    return (
        <DeleteOverlayContext.Provider value={providerValue}>
            <DeleteOverlay onDelete={onDelete} {...rest}>
                {children}
            </DeleteOverlay>
        </DeleteOverlayContext.Provider>
    );
};

interface DeleteCardOverlayTriggerProps {
    children?: React.ReactElement;
    title?: string;
    className?: string;
}
/**
 * Adds onClick function that opens children to open overlay.
 */
export const DeleteCardOverlayTrigger = ({ children, title = '', className = '' }: DeleteCardOverlayTriggerProps) => {
    const { handleOpen, overlayOpen } = useDeleteOverlayContext();

    if (children) {
        return React.cloneElement(children, { ...children.props, onClick: handleOpen });
    }

    return <BinButton title={title} showButton={overlayOpen} onClick={handleOpen} className={className} />;
};
