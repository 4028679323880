import React, { createContext, useEffect, useContext } from 'react';

import { ProductVariant } from '../../../../types/api/products/Product';
import { ProductsQuantity } from '../../../../types/api/products/ProductsQuantity';
import { VariantQuantityReducerState } from './hooks/useVariantQuantityReducer/variantQuantityReducer';
import useVariantQuantityReducer from './hooks/useVariantQuantityReducer/useVariantQuantityReducer';
import useBasketBlockers from './hooks/useBasketBlockers/useBasketBlockers';
import { InitialVariant } from './hooks/useVariantQuantityReducer/helpers/getDefaultVariant/getDefaultVariant';

// Callback function that runs when the variant or quantity changes.
export type OnChangeData = Omit<VariantQuantityReducerState, 'variants' | 'refills' | 'isRefill'>;
export type OnChange = (config: OnChangeData) => void;

interface Props {
    variants: ProductVariant[];
    onChange?: OnChange;
    initialVariant?: InitialVariant; // A variant for the reducer state. If the variant is not found, a default will be calculated.
    children: React.ReactNode;
}

type ReducerReturnType = ReturnType<typeof useVariantQuantityReducer>;

const VariantsContext = createContext<Props['variants']>([]);
const TreatmentSelectorUpdaterContext = createContext<ReducerReturnType['dispatch']>(() => {});
const SelectedVariantContext = createContext<ReducerReturnType['variant']>({} as ReducerReturnType['variant']);
const SelectedQuantityContext = createContext<ReducerReturnType['quantity']>(null);
const BasketBlockerContext = createContext<ReturnType<typeof useBasketBlockers>>([]);
const IsRefillContext = createContext<ReducerReturnType['isRefill']>(false);

/**
 * A collection of contexts needed for the treatment selector.
 * - useVariantsContext: The variants of the treatment.
 * - TreatmentSelectorUpdaterContext: Used to update the selected variant and / or quantity.
 * - SelectedVariantContext: The currently selected variant.
 * - SelectedQuantityContext: The currently selected quantity.
 * - BasketBlockerContext: The basket blockers for the currently selected variant.
 */
export const TreatmentSelectorProvider = ({ variants, onChange, initialVariant, children }: Props) => {
    const { variant, quantity, isRefill, dispatch } = useVariantQuantityReducer({ variants, initialVariant });
    const basketBlockers = useBasketBlockers(variant);

    // onChange has been ignored as it is not a dependency of this hook. Runs a callback function when the
    // variant or quantity changes.
    useEffect(() => {
        if (!onChange) return;
        onChange({ variant, quantity });
    }, [variant, quantity]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <VariantsContext.Provider value={variants}>
            <TreatmentSelectorUpdaterContext.Provider value={dispatch}>
                <SelectedVariantContext.Provider value={variant}>
                    <SelectedQuantityContext.Provider value={quantity}>
                        <IsRefillContext.Provider value={isRefill}>
                            <BasketBlockerContext.Provider value={basketBlockers}>{children}</BasketBlockerContext.Provider>
                        </IsRefillContext.Provider>
                    </SelectedQuantityContext.Provider>
                </SelectedVariantContext.Provider>
            </TreatmentSelectorUpdaterContext.Provider>
        </VariantsContext.Provider>
    );
};

/**
 * Hook to get the available variants from the context in the component above.
 */
export const useVariantsContext = () => {
    const variants = useContext(VariantsContext);
    if (variants === undefined) throw new Error('useVariantsContext must be used within a VariantsContextProvider.');
    return variants;
};

/**
 * Hook to get the reducer updater functions from the context in the component above.
 */
export const useTreatmentSelectorUpdaterContext = () => {
    const dispatch = useContext(TreatmentSelectorUpdaterContext);
    if (dispatch === undefined)
        throw new Error('useTreatmentSelectorUpdaterContext must be used within a TreatmentSelectorUpdaterProvider.');

    return {
        updateQuantity: (quantity: ProductsQuantity) =>
            dispatch({
                type: 'UPDATE_QUANTITY',
                payload: {
                    quantity,
                },
            }),
        updateVariant: (variant: ProductVariant) =>
            dispatch({
                type: 'UPDATE_VARIANT',
                payload: {
                    variant,
                },
            }),
    };
};

/**
 * Hook to get the selected variant from the context in the component above.
 */
export const useSelectedVariantContext = () => {
    const variant = useContext(SelectedVariantContext);
    if (variant === undefined) throw new Error('useSelectedVariantContext must be used within a SelectedVariantProvider.');
    return variant;
};

/**
 * Hook to get the selected quantity from the context in the component above.
 */
export const useSelectedQuantityContext = () => {
    const quantity = useContext(SelectedQuantityContext);
    if (quantity === undefined) throw new Error('useSelectedQuantityContext must be used within a SelectedQuantityProvider.');
    return quantity;
};

/**
 * Hook to get the basket blockers from the context in the component above.
 */
export const useBasketBlockerContext = () => {
    const basketBlockers = useContext(BasketBlockerContext);
    if (basketBlockers === undefined) throw new Error('useBasketBlockerContext must be used within a BasketBlockerProvider.');
    return basketBlockers;
};

/**
 * Hook to get the basket blockers from the context in the component above.
 */
export const useIsRefillContext = () => {
    const isRefill = useContext(IsRefillContext);
    if (isRefill === undefined) throw new Error('useIsRefillContext must be used within a IsRefillProvider.');
    return isRefill;
};

export interface ChangeData {
    quantity: ReturnType<typeof useSelectedQuantityContext>;
    variant: ReturnType<typeof useSelectedVariantContext>;
}
