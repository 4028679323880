import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import loadable from '@loadable/component';

import { useNavigationContext } from '../../../data/context/navigationContext';
import { useBasketContext } from '../../../data/context/basketContext/basketContext';

import BaseIcon from '../../_new_components/common/Icons/BaseIcon/BaseIcon';
import IconBasket from '../../_ui/_blocks/Icons/IconBasket';
import IconMenu from '../../_ui/_blocks/Icons/Navigation/IconMenu';
import NumberIncrement from '../../_ui/_blocks/NumberIncrement/NumberIncrement';
import ImagePreload from '../../_ui/_blocks/ImagePreload';
import MainNavigation from '../CraftMenuTypes/MainNavigation/MainNavigation';
import AccountDropdown from '../AccountDropdown/AccountDropdown';
import Container from '../../_new_components/common/Container/Container';
import Button from '../../_new_components/common/Buttons/Button/Button';
import Typography from '../../_new_components/common/Typography/Typography';
import SearchInput from '../../Search/SearchInput/SearchInput';

import * as styles from './Header.module.css';

export const LocalStorageWarning = loadable(() => import('../LocalStorageWarning/LocalStorageWarning'));

export interface HeaderProps {
    disableMainNav?: boolean;
    disableSearch?: boolean;
    disableAccount?: boolean;
    disableBasket?: boolean;
    disableBurgerMenu?: boolean;
    className?: string;
}
/**
 * The main header component for the website.
 */
const Header = ({ disableMainNav, disableSearch, disableAccount, disableBasket, disableBurgerMenu, className = '' }: HeaderProps) => {
    const { setSidebarNavIsOpen } = useNavigationContext();
    // @ts-expect-error
    const { basket, setBasketIsOpen } = useBasketContext();

    const handleOpenBasket = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setBasketIsOpen((s: boolean) => !s);
    };

    const handleSidebarOpen = () => setSidebarNavIsOpen((s: boolean) => !s);

    const shouldRenderLinks = !disableAccount || !disableBasket || !disableBurgerMenu;

    return (
        <Fragment>
            <LocalStorageWarning />
            <div className={`${styles.header} ${className}`}>
                <Container as="header" size="large" className="flex gap-150 items-center relative">
                    <Link
                        to="/"
                        title="The Independent Pharmacy homepage"
                        aria-label="The Independent Pharmacy homepage"
                        className={styles.logo}
                    >
                        <ImagePreload src="/images/logo.svg" alt="The Independent Pharmacy" width={124} height={42} />
                    </Link>
                    {!disableSearch ? (
                        <div className={styles.search}>
                            <SearchInput name="header-search" variant="siteHeader" placeholder="Search..." invokesOverlay />
                        </div>
                    ) : null}
                    {shouldRenderLinks ? (
                        <div className={styles.links}>
                            {!disableAccount ? <AccountDropdown disableAccountDropdownOnMobile /> : null}
                            {!disableBasket ? (
                                <Button
                                    variant="none"
                                    aria-label="Open your basket"
                                    title="Open your basket"
                                    onClick={handleOpenBasket}
                                    className={styles.link}
                                >
                                    <Typography typeset="heading" size="100" color="white">
                                        Basket
                                    </Typography>
                                    <BaseIcon>
                                        <IconBasket />
                                    </BaseIcon>
                                    {basket?.items?.length ? (
                                        <div className={styles.basketCount}>
                                            <NumberIncrement number={basket.items.length} />
                                        </div>
                                    ) : null}
                                </Button>
                            ) : null}
                            {!disableBurgerMenu ? (
                                <Button
                                    variant="none"
                                    aria-label="Open the main menu"
                                    title="Open the main menu"
                                    onClick={handleSidebarOpen}
                                    className={styles.link}
                                >
                                    <Typography typeset="heading" size="100" color="white">
                                        Menu
                                    </Typography>
                                    <BaseIcon color="white">
                                        <IconMenu />
                                    </BaseIcon>
                                </Button>
                            ) : null}
                        </div>
                    ) : null}
                </Container>
            </div>
            {!disableMainNav ? <MainNavigation /> : null}
        </Fragment>
    );
};

export default Header;
