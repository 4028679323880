import React, { ReactNode } from 'react';

import sendSentryMessage from '../../helpers/sendSentryMessage';
import IconCross from '../_ui/_blocks/Icons/Navigation/IconCross';
import BaseIcon from '../_ui/_blocks/Icons/BaseIcon';
import Attention from '../_new_components/common/Attention/Attention';

interface ModalErrorBoundaryProps {
    children: ReactNode;
    onClose: () => void;
}

interface ModalErrorBoundaryState {
    hasError: boolean;
    errorMessage: string | null;
}

export default class ModalErrorBoundary extends React.Component<ModalErrorBoundaryProps, ModalErrorBoundaryState> {
    constructor(props: ModalErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, errorMessage: null };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // You can also log the error to an error reporting service
        sendSentryMessage(error, (scope) => {
            scope.setExtra('errorInfo', errorInfo);
        });
    }

    render() {
        const { errorMessage, hasError } = this.state;
        const { children, onClose } = this.props;

        // You can render any custom fallback UI
        if (hasError) {
            return (
                <div className="card flow--large dialog dialog--small">
                    <header className="flow--small">
                        <div className="flex flex--space-between">
                            <h3 className="text-title-six">Error</h3>
                            <button type="button" className="dialog__close" onClick={onClose} title="Close treatment refill">
                                <BaseIcon className="icon--midnight icon--small">
                                    <IconCross />
                                </BaseIcon>
                            </button>
                        </div>
                    </header>
                    <Attention type="info" heading="There was an unexpected error">
                        <p className="text-metadata text-color--slate">{errorMessage} Please contact support.</p>
                    </Attention>
                    <div className="breathe text-align--right">
                        <button type="button" className="button" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            );
        }

        return children;
    }
}
