import React from 'react';
import clx from 'classnames';

import { getLineHeightClass, lineHeights } from './helpers/getLineHeight';
import { getFontSizeClass, fontSizes } from './helpers/getFontSize';
import { getTypesetClass, typesets } from './helpers/getTypeset';
import { getFontWeightClass, fontWeights } from './helpers/getFontWeight';
import { getFontColorClass, fontColors } from './helpers/getFontColor';

export const tags = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p: 'p',
    pre: 'pre',
    a: 'a',
    span: 'span',
    div: 'div',
    dt: 'dt',
    dd: 'dd',
    label: 'label',
    blockquote: 'blockquote',
    address: 'address',
    li: 'li',
    legend: 'legend',
};

interface Props extends React.HTMLAttributes<HTMLElement> {
    as?: keyof typeof tags;
    className?: string;
    typeset?: keyof typeof typesets;
    size?: keyof typeof fontSizes;
    lineHeight?: keyof typeof lineHeights;
    weight?: keyof typeof fontWeights;
    color?: keyof typeof fontColors;
    underline?: boolean;
    italic?: boolean;
    lineThrough?: boolean;
    children?: React.ReactNode;
    htmlFor?: string;
}

const Typography = ({
    as: Tag = 'span',
    className,
    typeset = 'paragraph',
    size,
    lineHeight,
    weight,
    color,
    underline,
    italic,
    lineThrough,
    children,
    ...props
}: Props) => {
    const typographyClassName = clx(
        className,
        getTypesetClass(typeset),

        getFontWeightClass(weight),
        getFontSizeClass(size),
        getLineHeightClass(lineHeight),
        getFontColorClass(color),
        underline ? 'underline' : '',
        italic ? 'italic' : '',
        lineThrough ? 'line-through' : ''
    );

    return (
        <Tag {...props} className={typographyClassName}>
            {children}
        </Tag>
    );
};

export default Typography;
