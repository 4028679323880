import React, { useEffect, useRef } from 'react';

import Flash, { Types } from '@components/_ui/_blocks/Flash';
import { useCookieWatcher } from '@helpers/cookie';
import { DEFAULT_FLASH_MESSAGE_KEY, FLASH_EXPIRES_IN } from './config/config';

interface Props {
    cookieKey: string;
    type?: keyof typeof Types;
    important?: boolean;
    className?: string;
}

const FlashMessage = ({ type = 'info', important = false, cookieKey = DEFAULT_FLASH_MESSAGE_KEY, className }: Props) => {
    const [message, forceCookieRemove] = useCookieWatcher(cookieKey);

    const timeout = useRef<NodeJS.Timeout | undefined>();

    useEffect(() => {
        timeout.current = setTimeout(() => forceCookieRemove(), FLASH_EXPIRES_IN * 60 * 1000);

        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        };
    }, []);

    const handleClose = () => {
        forceCookieRemove();
    };

    return message ? (
        <div className={className}>
            <Flash type={type} important={important} message={message} onClose={handleClose} />
        </div>
    ) : null;
};

export default FlashMessage;
