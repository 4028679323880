import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Typography from '@components/_new_components/common/Typography/Typography';

import SocialIcons from '../_ui/_blocks/SocialIcons';
import ResponsiblePharmacist from './ResponsiblePharmacist';

import * as styles from './Footer.module.css';

interface OpeningTime {
    openingDay: string;
    openingHours: string;
}

interface Data {
    craftcms: {
        sitewide: {
            contactSupportEmail: string;
            contactTelephoneNumber: string;
            openingTimes: OpeningTime[];
        };
    };
}

const CompanyDetails = (): JSX.Element => {
    const data: Data = useStaticQuery(graphql`
        {
            craftcms {
                sitewide: globalSet(handle: "sitewideContent") {
                    ... on CraftCMS_sitewideContent_GlobalSet {
                        contactSupportEmail
                        contactTelephoneNumber
                        openingTimes {
                            ... on CraftCMS_openingTimes_openingTime_BlockType {
                                openingDay
                                openingHours
                            }
                        }
                    }
                }
            }
        }
    `);

    const { sitewide } = data.craftcms;

    return (
        <div className={`${styles.footer_section} ${styles.space_y_200} ${styles.footer_company_information}`}>
            <div className={`${styles.space_y_200} ${styles.fluid}`}>
                <div className={styles.footer_section}>
                    <Typography typeset="heading" size="090" lineHeight="1000">
                        Email
                    </Typography>
                    <a
                        href={`mailto:${sitewide.contactSupportEmail}`}
                        title={`Email The Independent Pharmacy - ${sitewide.contactSupportEmail}`}
                        className="typeset-paragraph type-090"
                    >
                        {sitewide.contactSupportEmail}
                    </a>
                </div>

                <div className={styles.footer_section}>
                    <Typography typeset="heading" size="090">
                        Telephone
                    </Typography>
                    <a
                        href={`tel:${sitewide.contactTelephoneNumber.replace(/ /g, '')}`}
                        className="typeset-paragraph type-090"
                        title={`Call The Independent Pharmacy now on ${sitewide.contactTelephoneNumber}`}
                    >
                        {sitewide.contactTelephoneNumber}
                    </a>
                </div>
                {sitewide.openingTimes ? (
                    <div className={styles.footer_section}>
                        <Typography typeset="heading" size="090">
                            Opening Times
                        </Typography>
                        <Typography typeset="paragraph" size="090">
                            {sitewide.openingTimes.map((openingTime) => `${openingTime.openingDay} ${openingTime.openingHours}`)}
                        </Typography>
                    </div>
                ) : null}
            </div>
            <div className={styles.space_y_200}>
                <SocialIcons />

                <ResponsiblePharmacist />
            </div>
        </div>
    );
};

export default CompanyDetails;
